import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

const DynamicHeader = () => {
  const { t } = useTranslation();

  return (
    <Helmet>
      <html lang={t("htmlLang")} />
      <title>{t("title")}</title>
      <meta name="description" content={t("metaDescription")} />
      <meta name="theme-color" content="#000000" />
      <meta name="BoscoBoys Distributors" content={t("slogan")} />
    </Helmet>
  );
};

export default DynamicHeader;
