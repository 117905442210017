import React, { useEffect, Suspense, lazy } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./i18n";
import LoadingScreen from "./components/LoadingScreen";
import DynamicHeader from "./components/DynamicHeader";
const NotFound = lazy(() => import("./components/NotFound"));
const RandomServices = lazy(() =>
  import("./components/navbar/services/RandomServices")
);

// Lazy-loaded Main Components
const Navbar = lazy(() => import("./components/Navbar"));
const Footer = lazy(() => import("./components/Footer"));
const GetStartedDefault = lazy(() => import("./components/GetStartedDefault"));

// Lazy-loaded Home Components
const HomeComponents = {
  Hero: lazy(() => import("./components/Hero")),
  GetStartedHome: lazy(() => import("./components/GetStartedHome")),
  Services: lazy(() => import("./components/Services")),
  CompanyStats: lazy(() => import("./components/CompanyStats")),
  AboutUs: lazy(() => import("./components/AboutUs")),
  TrustedPartners: lazy(() => import("./components/TrustedPartners")),
};

// Lazy-loaded Privacy Policy Components
const PrivacyPolicyComponents = {
  PPHero: lazy(() => import("./components/privacy/PPHero")),
  PPContent: lazy(() => import("./components/privacy/PPContent")),
};

// Lazy-loaded Service Components
const ServiceComponents = {
  Hero: lazy(() => import("./components/navbar/services/ServiceHero")),
  Content: lazy(() => import("./components/navbar/services/ServiceContent")),
  ServiceExcellence: lazy(() =>
    import("./components/navbar/services/ServiceExcellence")
  ),
};

// Lazy-loaded Careers Components
const CareersComponents = {
  Hero: lazy(() => import("./components/navbar/careers/CareersHero")),
  Content: lazy(() => import("./components/navbar/careers/CareersContent")),
  Testimonials: lazy(() =>
    import("./components/navbar/careers/CareersTestimonials")
  ),
  TrainingSafety: lazy(() =>
    import("./components/navbar/careers/TrainingSafety")
  ),
};

// Lazy-loaded About Components
const AboutComponents = {
  Hero: lazy(() => import("./components/navbar/about/AboutHero")),
  Content: lazy(() => import("./components/navbar/about/AboutContent")),
};

// Lazy-loaded Contact Components
const ContactComponents = {
  Hero: lazy(() => import("./components/navbar/contact/ContactHero")),
  Content: lazy(() => import("./components/navbar/contact/ContactContent")),
};

// Lazy-loaded Individual Service Components
const individualServiceComponents = {
  DVTHero: lazy(() =>
    import(
      "./components/navbar/services/individualServices/dryVanTrucking/DVTHero"
    )
  ),
  DVTContent: lazy(() =>
    import(
      "./components/navbar/services/individualServices/dryVanTrucking/DVTContent"
    )
  ),
  TAFHero: lazy(() =>
    import(
      "./components/navbar/services/individualServices/truckingFreight/TAFHero"
    )
  ),
  TAFContent: lazy(() =>
    import(
      "./components/navbar/services/individualServices/truckingFreight/TAFContent"
    )
  ),
  TLHero: lazy(() =>
    import("./components/navbar/services/individualServices/ftlltl/TLHero")
  ),
  TLContent: lazy(() =>
    import("./components/navbar/services/individualServices/ftlltl/TLContent")
  ),
  GTAHero: lazy(() =>
    import(
      "./components/navbar/services/individualServices/gtaDeliveries/GTAHero"
    )
  ),
  GTAContent: lazy(() =>
    import(
      "./components/navbar/services/individualServices/gtaDeliveries/GTAContent"
    )
  ),
  FFHero: lazy(() =>
    import(
      "./components/navbar/services/individualServices/freightForwarding/FFHero"
    )
  ),
  FFContent: lazy(() =>
    import(
      "./components/navbar/services/individualServices/freightForwarding/FFContent"
    )
  ),
  WHHero: lazy(() =>
    import(
      "./components/navbar/services/individualServices/warehousingSolutions/WHHero"
    )
  ),
  WHContent: lazy(() =>
    import(
      "./components/navbar/services/individualServices/warehousingSolutions/WHContent"
    )
  ),
  PDHero: lazy(() =>
    import(
      "./components/navbar/services/individualServices/parcelDelivery/PDHero"
    )
  ),
  PDContent: lazy(() =>
    import(
      "./components/navbar/services/individualServices/parcelDelivery/PDContent"
    )
  ),
  CDHero: lazy(() =>
    import(
      "./components/navbar/services/individualServices/crossDocking/CDHero"
    )
  ),
  CDContent: lazy(() =>
    import(
      "./components/navbar/services/individualServices/crossDocking/CDContent"
    )
  ),
  HTHero: lazy(() =>
    import(
      "./components/navbar/services/individualServices/hazmatTransport/HTHero"
    )
  ),
  HTContent: lazy(() =>
    import(
      "./components/navbar/services/individualServices/hazmatTransport/HTContent"
    )
  ),
  NPHero: lazy(() =>
    import(
      "./components/navbar/services/individualServices/newspaperDistribution/NPHero"
    )
  ),
  NPContent: lazy(() =>
    import(
      "./components/navbar/services/individualServices/newspaperDistribution/NPContent"
    )
  ),
  EComHero: lazy(() =>
    import(
      "./components/navbar/services/individualServices/ecomDelivery/EComHero"
    )
  ),
  EComContent: lazy(() =>
    import(
      "./components/navbar/services/individualServices/ecomDelivery/EComContent"
    )
  ),
  TTPHero: lazy(() =>
    import(
      "./components/navbar/services/individualServices/truckTrailerParking/TTPHero"
    )
  ),
  TTPContent: lazy(() =>
    import(
      "./components/navbar/services/individualServices/truckTrailerParking/TTPContent"
    )
  ),
};

// Page Components
const Home = () => (
  <>
    <HomeComponents.Hero />
    <HomeComponents.GetStartedHome />
    <HomeComponents.CompanyStats />
    <HomeComponents.Services />
    <HomeComponents.TrustedPartners />
    <HomeComponents.AboutUs />
  </>
);

const ServicesPage = () => (
  <>
    <ServiceComponents.Hero />
    <ServiceComponents.Content />
    <ServiceComponents.ServiceExcellence />
    <GetStartedDefault />
  </>
);

const CareersPage = () => (
  <>
    <CareersComponents.Hero />
    <CareersComponents.Content />
    <CareersComponents.Testimonials />
    <CareersComponents.TrainingSafety />
    <GetStartedDefault />
  </>
);

const AboutPage = () => (
  <>
    <AboutComponents.Hero />
    <AboutComponents.Content />
    <GetStartedDefault />
  </>
);

const ContactPage = () => (
  <>
    <ContactComponents.Hero />
    <ContactComponents.Content />
    <GetStartedDefault />
  </>
);

const PrivacyPage = () => (
  <>
    <PrivacyPolicyComponents.PPHero />
    <PrivacyPolicyComponents.PPContent />
    <GetStartedDefault />
  </>
);

const IndividualServicePage = ({ Hero, Content, serviceType }) => (
  <>
    <Hero />
    <Content />
    <RandomServices currentService={serviceType} />
    <GetStartedDefault />
  </>
);

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    const handleScrollToTop = () => {
      window.scrollTo(0, 0);
    };

    // Scroll to top on route change
    handleScrollToTop();

    // Add event listener for link clicks
    const handleLinkClick = (e) => {
      const target = e.target;
      if (
        target.tagName === "A" &&
        target.href.includes(window.location.origin)
      ) {
        handleScrollToTop();
      }
    };

    document.addEventListener("click", handleLinkClick);

    return () => {
      // Cleanup event listener on unmount
      document.removeEventListener("click", handleLinkClick);
    };
  }, [pathname]);

  return null;
};

function App() {
  const { i18n } = useTranslation();

  useEffect(() => {
    const savedLanguage = localStorage.getItem("language") || "en";
    if (i18n.language !== savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
  }, [i18n]);

  return (
    <Router>
      <ScrollToTop />
      <div>
        <Suspense fallback={<LoadingScreen />}>
          <DynamicHeader />
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/services" element={<ServicesPage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/careers" element={<CareersPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/privacyPolicy" element={<PrivacyPage />} />

            {/* Individual Service Pages */}
            <Route
              path="/services/dryVanTrucking"
              element={
                <IndividualServicePage
                  Hero={individualServiceComponents.DVTHero}
                  Content={individualServiceComponents.DVTContent}
                  serviceType="dryVanTrucking"
                />
              }
            />
            <Route
              path="/services/truckingAndFreight"
              element={
                <IndividualServicePage
                  Hero={individualServiceComponents.TAFHero}
                  Content={individualServiceComponents.TAFContent}
                  serviceType="truckingAndFreight"
                />
              }
            />
            <Route
              path="/services/ftlAndLtl"
              element={
                <IndividualServicePage
                  Hero={individualServiceComponents.TLHero}
                  Content={individualServiceComponents.TLContent}
                  serviceType="ftlAndLtl"
                />
              }
            />
            <Route
              path="/services/gtaUrbanDeliveries"
              element={
                <IndividualServicePage
                  Hero={individualServiceComponents.GTAHero}
                  Content={individualServiceComponents.GTAContent}
                  serviceType="gtaUrbanDeliveries"
                />
              }
            />
            <Route
              path="/services/freightForwarding"
              element={
                <IndividualServicePage
                  Hero={individualServiceComponents.FFHero}
                  Content={individualServiceComponents.FFContent}
                  serviceType="freightForwarding"
                />
              }
            />
            <Route
              path="/services/warehousingSolutions"
              element={
                <IndividualServicePage
                  Hero={individualServiceComponents.WHHero}
                  Content={individualServiceComponents.WHContent}
                  serviceType="warehousingSolutions"
                />
              }
            />
            <Route
              path="/services/parcelDelivery"
              element={
                <IndividualServicePage
                  Hero={individualServiceComponents.PDHero}
                  Content={individualServiceComponents.PDContent}
                  serviceType="parcelDelivery"
                />
              }
            />
            <Route
              path="/services/crossDocking"
              element={
                <IndividualServicePage
                  Hero={individualServiceComponents.CDHero}
                  Content={individualServiceComponents.CDContent}
                  serviceType="crossDocking"
                />
              }
            />
            <Route
              path="/services/hazmatTransport"
              element={
                <IndividualServicePage
                  Hero={individualServiceComponents.HTHero}
                  Content={individualServiceComponents.HTContent}
                  serviceType="hazmatTransport"
                />
              }
            />
            <Route
              path="/services/newspaperDistribution"
              element={
                <IndividualServicePage
                  Hero={individualServiceComponents.NPHero}
                  Content={individualServiceComponents.NPContent}
                  serviceType="newspaperDistribution"
                />
              }
            />
            <Route
              path="/services/ecommerceDelivery"
              element={
                <IndividualServicePage
                  Hero={individualServiceComponents.EComHero}
                  Content={individualServiceComponents.EComContent}
                  serviceType="ecommerceDelivery"
                />
              }
            />
            <Route
              path="/services/truckTrailerParking"
              element={
                <IndividualServicePage
                  Hero={individualServiceComponents.TTPHero}
                  Content={individualServiceComponents.TTPContent}
                  serviceType="truckTrailerParking"
                />
              }
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
          <Footer />
        </Suspense>
      </div>
    </Router>
  );
}

export default App;
