import React from "react";

const LoadingScreen = () => (
  <div className="flex flex-col items-center justify-center min-h-screen">
    <div className="animate-spin rounded-full h-32 w-32 border-t-4 border-light-accent dark:border-dark-accent"></div>
    <p className="mt-4 text-xl">Loading...</p>
  </div>
);

export default LoadingScreen;
